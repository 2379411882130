<template>
<div v-if="loginShow">
    <MasterHeader />
    <v-container class="blog_box" fluid>
        <h1 class="text-center mb-10 page_title">ブログ 設定</h1>
        <p class="text-center">ブログの追加・削除ができます</p>

        <v-card class="px-4 py-3">
            <div class="f-12 text-center py-6">ブログの追加</div>
            <v-form ref="form">
                <v-text-field v-model="title" :rules="requredRules" clearable label="タイトル*" required type="text" maxlength="255" outlined />
                <v-textarea v-model="text" label="本文*" class="mt-5" :rules="requredRules" auto-grow outlined rows="12" row-height="15" maxlength="5000"></v-textarea>

                <v-file-input show-size label="写真１" outlined @change="uploadFile1" accept="image/*" v-if="show1" />
                <v-file-input show-size label="写真２" outlined @change="uploadFile2" accept="image/*" v-if="show2" />
                <v-file-input show-size label="写真３" outlined @change="uploadFile3" accept="image/*" v-if="show3" />
                <v-file-input show-size label="写真４" outlined @change="uploadFile4" accept="image/*" v-if="show4" />

                <v-text-field v-model="hour" style="width:100px" class="float-right mx-2" :rules="hourRules" clearable label="時刻*" required type="text" maxlength="5" outlined />
                <v-text-field v-model="day" style="width:150px" class="float-right mx-2" :rules="dayRules" clearable label="投稿日*" required type="text" maxlength="10" outlined />
                <div class="clear" />
                <div class="text-center">
                    <v-btn x-large color="blue" dark class="mr-5 mb-3" @click="save()">保存</v-btn>
                </div>
            </v-form>
        </v-card>
        <Content :Master="true" />
    </v-container>

</div>
</template>

<script>
import Content from "@/components/BlogContent.vue"
import axios from "axios"
import Login from '@/mixins/login'
import d from "@/d"
import moment from "moment"

import MasterHeader from '@/components/MasterHeader.vue'
export default {
    mixins: [Login],
    data() {
        return {
            requredRules: [v => !!v || "入力必須です"],
            dayRules: [v =>
                /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || "「2021-01-01」の型です"
            ],
                  hourRules: [v =>
                /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/.test(v) || "「00:00」の型です"
            ],
            title: "",
            text: "",
            formData: [],
            bit: 3145728,
            show1: true,
            show2: true,
            show3: true,
            show4: true,
            day: moment().format("YYYY-MM-DD"),
            hour: moment().format("HH:mm")
        }
    },
    props: {
        ScroY: Number
    },
    components: {
        Content,
        MasterHeader
    },
    mounted() {
        this.formData = new FormData()
    },
    methods: {
        async uploadFile1(val) {
            this.checkSize(val, 1)
            this.formData.append('file1', val)
        },
        async uploadFile2(val) {
            this.checkSize(val, 2)
            this.formData.append('file2', val)
        },
        async uploadFile3(val) {
            this.checkSize(val, 3)
            this.formData.append('file3', val)
        },
        async uploadFile4(val) {
            this.checkSize(val, 4)
            this.formData.append('file4', val)
        },
        async checkSize(val, no) {
            if (val.size > this.bit) {
                this['show' + no] = false
                alert('画像サイズは１枚あたり３MBまでです')
                await d.sleep(10)
                this['show' + no] = true
                return false
            }
        },

        async save() {
            if (this.$refs.form.validate()) {
                this.formData.append('title', this.title)
                this.formData.append('text', this.text)
                this.formData.append('date', this.day+" "+this.hour)
                await axios.post('/master/blog_up', this.formData)

                window.location.href = "/master/blog";
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/blog.scss";
</style>
